<template>
  <div>
    <!-- Title -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('speakers.name') }}</label>
      <div class="col-md-7">
        <input v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" class="form-control" type="text" name="name">
        <has-error :form="form" field="name" />
      </div>
    </div>

    <!-- Thumbnail -->
    <div class="form-group row">
      <label class="col-md-3 col-form-label text-md-right">{{ $t('speakers.thumbnail') }}</label>
      <div class="col-md-7">
        <img v-bind:src="form.thumbnailUrl" v-if="form.thumbnailUrl" class="mg-b-15"/>
        <div class="custom-file">
          <input v-on:change="changeThumbnail" :class="{ 'is-invalid': form.errors.has('thumbnail') }" class="custom-file-input" type="file" name="thumbnail" id="thumbnail">
          <has-error :form="form" field="thumbnail" />
          <label class="custom-file-label" for="thumbnail">{{ thumbnailLabel }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      form: { 
        type: Object,
        required: true
      }
    },
    computed: {
      thumbnailLabel: function() {
        return this.form.thumbnail ? this.form.thumbnail.name : 'Choose file';
      }
    },
    methods: {
      changeThumbnail(e) {
        const file = e.target.files[0];
        this.form.thumbnail = file;
      }
    }
  }
</script>

<style scope="scss">
img {
  max-width: 100%;
  max-height: 15rem;
}
.input-group-prepend .input-group-text svg {
  width: 1rem !important;
  height: 1rem !important;
}
</style>