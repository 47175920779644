<template>
  <card :title="$t('speakers.info')">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('speakers.updated')" />

      <speaker-form :form="form"></speaker-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SpeakerForm from "../../accounts/speakers/form"
import { mapGetters } from "vuex"

export default {
  scrollToTop: false,
  components: {
    "speaker-form": SpeakerForm
  },
  data: () => ({
    form: new Form({
      name: "",
      thumbnailUrl: "",
      thumbnail: null,
    })
  }),
  async created() {
    let speaker = this.speakers[this.$route.params.id];
    if (!speaker) {
      await this.$store.dispatch('admin/fetchSpeakers', {id: [this.$route.params.id]});
      speaker = this.speakers[this.$route.params.id];
    } 
    this.refresh(speaker);    
  },
  computed: {
    ...mapGetters({
      speakers: "admin/speakers"
    })
  },
  methods: {
    refresh(speaker) {
      this.form.keys().forEach(key => {
        this.form[key] = speaker[key];
      });
    },
    async update() {
      let vm = this;
      this.$store.dispatch("admin/updateSpeakers", {form: this.form, params: {id: this.$route.params.id}}).then(() => {
        // Update the form
        let speaker = vm.speakers[vm.$route.params.id];
        vm.refresh(speaker);
      });     
    }
  }
};
</script>
